<template>
  <div class="HeaderTitle">
    <TextEllipsis>
      <slot></slot>
    </TextEllipsis>
  </div>
</template>

<script setup lang="ts"></script>

<style scoped>
.HeaderTitle {
  display: flex;
  align-items: center;
  gap: 8px;
  font-weight: 500;
  color: var(--gray-2);
  overflow: hidden;
}
</style>
